var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"الاسم","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"name","rules":"minname:2|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"النسبة","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":"full-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name"},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"الجنس","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"options":_vm.genderOptions,"clearable":false,"input-id":"user-status"},model:{value:(_vm.userData.gender),callback:function ($$v) {_vm.$set(_vm.userData, "gender", $$v)},expression:"userData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"الدور","label-for":"user-role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"multiple":true,"input-id":"user-role"},model:{value:(_vm.userData.role_id),callback:function ($$v) {_vm.$set(_vm.userData, "role_id", $$v)},expression:"userData.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"القسم","label-for":"user-department"}},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.departmentOptions,"reduce":function (val) { return val.value; },"input-id":"user-role"},model:{value:(_vm.userData.department_id),callback:function ($$v) {_vm.$set(_vm.userData, "department_id", $$v)},expression:"userData.department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"المنصب","label-for":"user-department"}},[_c('validation-provider',{attrs:{"name":"position","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.positionOptions,"reduce":function (val) { return val.value; },"input-id":"user-role"},model:{value:(_vm.userData.position_id),callback:function ($$v) {_vm.$set(_vm.userData, "position_id", $$v)},expression:"userData.position_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"الكود","label-for":"user-department"}},[_c('validation-provider',{attrs:{"name":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":"input-title"},model:{value:(_vm.userData.employee_code),callback:function ($$v) {_vm.$set(_vm.userData, "employee_code", $$v)},expression:"userData.employee_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.userData.role_id.includes(3) || _vm.userData.role_id.includes(6))?_c('b-col',{attrs:{"md":"6","xl":"3"}},[_c('validation-provider',{attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"service","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الخدمة")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optionService},model:{value:(_vm.userData.main_service_id),callback:function ($$v) {_vm.$set(_vm.userData, "main_service_id", $$v)},expression:"userData.main_service_id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2989774033)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"تاريخ الميلاد","label-for":"birth-date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"YYYY-MM-DD","config":{ maxDate: _vm.max }},model:{value:(_vm.userData.birth_date),callback:function ($$v) {_vm.$set(_vm.userData, "birth_date", $$v)},expression:"userData.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"الرقم","label-for":"mobile"}},[_c('validation-provider',{attrs:{"name":"mobile","rules":"max:14|regex:^([0-9]+)$|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile"},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"تاريخ التوظيف","label-for":"birth-date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"YYYY-MM-DD","config":{ maxDate: _vm.max }},model:{value:(_vm.userData.hire_date),callback:function ($$v) {_vm.$set(_vm.userData, "hire_date", $$v)},expression:"userData.hire_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"الايميل","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"19"}}),_c('label',{staticClass:"mb-0 ml-50",staticStyle:{"font-size":"15px"}},[_vm._v(" العنوان ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"العنوان الحالي","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country","placeholder":" "},model:{value:(_vm.userData.address),callback:function ($$v) {_vm.$set(_vm.userData, "address", $$v)},expression:"userData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"LogInIcon","size":"19"}}),_c('label',{staticClass:"mb-0 ml-50",staticStyle:{"font-size":"15px"}},[_vm._v(" معلومات الدخول ")])],1),_c('br'),_c('br'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"اسم المستخدم","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", $$v)},expression:"userData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"كلمة المرور","label":"كلمة المرور"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password2","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"تأكيد كلمة المرور","label":"تأكيد كلمة المرور"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","vid":"confirmPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":"password","state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.userData.confirm_password),callback:function ($$v) {_vm.$set(_vm.userData, "confirm_password", $$v)},expression:"userData.confirm_password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":_vm.addUserInfo}},[_c('span',[_vm._v(" حفظ ")])]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_c('span',[_vm._v(" إعادة تعيين")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }